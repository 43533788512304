<template>
  <b-row>
    <b-col>
      <b-card title="Doküman Arşivi">
        <b-table-simple
          bodered
          hover
        >
          <b-thead>
            <b-tr>
              <b-th>Doküman Adı</b-th>
              <b-th
                width="140"
                class="text-center"
              >
                <FeatherIcon icon="DownloadIcon" />
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="document in documents"
              :key="document.id"
            >
              <b-td>
                {{ document.title }}
                <div class="text-muted">
                  <small>{{ document.content }}</small>
                </div>
              </b-td>
              <b-td class="text-center">
                <b-button
                  v-if="document.file"
                  :href="folderUrl + document.file"
                  target="_blank"
                  variant="primary"
                  size="sm"
                >
                  <FeatherIcon icon="DownloadIcon" /> İndir
                </b-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BTableSimple, BThead, BTbody, BTr, BTh, BTd, BButton,
} from 'bootstrap-vue'

export default {
  name: 'Company',
  components: {
    BCard,
    BRow,
    BCol,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BButton,
  },
  data() {
    return {
      folderUrl: `${this.$store.state.app.baseURL}/media/portal/documents/`,
    }
  },
  computed: {
    documents() {
      return this.$store.getters['documents/getDocuments']
    },
  },
  created() {
    this.getDocuments()
  },
  methods: {
    getDocuments() {
      this.$store.dispatch('documents/documents')
    },
  },
}
</script>

<style scoped>

</style>
